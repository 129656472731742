/* You can add global styles to this file, and also import other style files */
// @use '@angular/material' as mat;
// @use 'material';
@use 'cdk';
@use 'normalize';
// @use 'theme' as *;
@use 'variable' as *;
@use 'external';

// 커스터마이징 된 부트스트랩
@use 'bootstrap5' as bs;
@use 'fonts';

@font-face {
  font-family: AppleSDGothicNeo;
  src: local('Apple SD Gothic Neo Regular'),
    url(/assets/fonts/AppleSDGothicNeo-Regular.woff2);
  font-weight: normal;
}

@font-face {
  font-family: AppleSDGothicNeo;
  src: local('Apple SD Gothic Neo Medium'),
    url(/assets/fonts/AppleSDGothicNeo-Medium.woff2);
  font-weight: 500;
}

@font-face {
  font-family: AppleSDGothicNeo;
  src: local('Apple SD Gothic Neo Bold'),
    url(/assets/fonts/AppleSDGothicNeo-Bold.woff2);
  font-weight: bold;
}

// material 값 대신 폰트 일괄 변경

:root {
  --bs-body-font-family: AppleSDGothicNeo, var(--bs-font-sans-serif);
}

:root:lang(ja) {
  // 폰트 fallback이 의도되지 작동하지 않은 점, 브라우저에서 어떤 폰트 사용중인지 알기 어려운 점으로 font-face 제거
  --bs-body-font-family: Meiryo UI, メイリオ, 'M PLUS 1p', AppleSDGothicNeo,
    var(--bs-font-sans-serif);
}

:root:lang(en) {
  --bs-body-font-family: Roboto, AppleSDGothicNeo, var(--bs-font-sans-serif);
}

html {
  font-size: $html-font-size;
  scroll-padding-top: $header-height-mobile;

  @media screen and (min-width: $min-width-m) {
    scroll-padding-top: $header-height-desktop + $header-menu-height-desktop;
  }
}

i.bi {
  $size: 20px;

  display: inline-block;
  width: $size;
  height: $size;
  font-size: $size;

  line-height: 1;
}

.modal-footer.center {
  justify-content: center;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.login-panel mat-dialog-container.mat-dialog-container {
  @media (max-width: $max-width-s) {
    border-radius: 0;
  }
}

.calendar-panel mat-dialog-container.mat-dialog-container {
  @media (max-width: $max-width-s) {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}

.fab-btn-area {
  z-index: 100;
}

// 어두운 배경 위에 클릭 가능한 요소 가이드를 밝게 표시
.highlight-link {
  .fab-btn-area {
    z-index: 101;
  }
  .mat-sidenav-container,
  .mat-sidenav,
  .mat-drawwer-content,
  .mat-drawer.mat-drawer-side,
  .mat-sidenav-content,
  .mat-tab-link-container {
    z-index: initial;
  }
  .menu-tabbar {
    z-index: 101;
  }
  .need-guide {
    position: relative;
    z-index: 102;
    border: 1px solid red !important;
    box-sizing: border-box;
    box-shadow: 0 0 0 2px red !important;
    filter: brightness(1.2);
  }
}

// 팝업 모달 기본 패딩 제거
.modal-no-padding {
  mat-dialog-container {
    padding: 0;
  }
}

.popup {
  max-width: 480px !important;
  mat-dialog-container {
    max-width: 480px;
  }
}

.mobile-popup-with-header {
  width: 100vw !important;
  height: calc(100vh - 50px);
  position: fixed;
  top: 50px;
  left: 0;
  bottom: 0;
  background-color: #fff;
}

.mobile-popup {
  width: 100vw !important;
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #fff;
}

// mat-drawer-container 배경 회색 제거
.mat-drawer-container {
  background-color: transparent;

  // position: sticky 사용하기 위해 설정
  overflow: unset !important;
}

.mat-drawer-content {
  // position: sticky 사용하기 위해 설정
  overflow: unset !important;
}

a {
  text-decoration: none;
  color: inherit;
}

.font-display-4 {
  font-size: 60px;
}

.font-title {
  font-size: 36px;
}

.font-subheading-2 {
  font-size: 30px;
}

.font-headline {
  font-size: 24px;
}

.font-subheading-1 {
  font-size: 22px;
}

.font-body {
  font-size: 16px;
}

.font-caption {
  font-size: 14px;
}

.font-input {
  font-size: 16px;
}

.font-button {
  font-size: 20px;
}

.font-ruby {
  font-size: 0.8em;
  margin-bottom: 2px !important;
}

.mobile {
  .font-display-4 {
    font-size: 20px;
  }

  .font-title {
    font-size: 14px;
  }

  .font-subheading-2 {
    font-size: 12px;
  }

  .font-headline {
    font-size: 11px;
  }

  .font-subheading-1 {
    font-size: 12px;
  }

  .font-body {
    font-size: 12px;
  }

  .font-caption {
    font-size: 11px;
  }

  .font-input {
    font-size: 12px;
  }

  .font-button {
    font-size: 12px;
  }
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 높이 100%로 채우게 변경
pw-img.full-height img {
  object-fit: cover;
  min-height: 100%;
}

// 시설의 이미지가 pw-img 컴포넌트 내에서 중앙에서 크기만큼 채우게 변경
pw-img.none-img img {
  object-fit: scale-down;
}

.fullscreen-modal {
  max-width: 100vw !important;
  width: 100vw;
  max-height: 100vh !important;
  height: 100vh;
  .mat-dialog-container {
    border-radius: 0;
    padding: 0;
  }

  #ifrWrapper {
    iframe {
      width: 100vw;
      height: 100vh;
    }
  }
}

.fullscreen-modal-small {
  @media (max-width: $max-width-s) {
    max-width: 100vw !important;
    width: 100vw;
    max-height: 100vh !important;
    height: 100vh;
    .mat-dialog-container {
      border-radius: 0;
      padding: 0;
    }

    #ifrWrapper {
      iframe {
        width: 100vw;
        height: 100vh;
      }
    }
  }
}

.gm-style-iw {
  padding: 0 !important;
}

.gm-style-iw-d {
  overflow: unset !important;
}

.gm-ui-hover-effect {
  display: none !important;
}

.mat-form-field-invalid .mat-form-filed-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-ripple {
  opacity: 0 !important;
  transform: unset !important;
  transition: unset !important;
}

[spacer] {
  margin: 0;
  width: 100%;
  height: 8px;
  background-color: $border-color-4;
}

.swiper-slide {
  height: unset;
  min-height: 100%;
}

.swiper-css-mode.mobile > .swiper-wrapper > .swiper-slide {
  padding-left: 16px;
  margin: 0 !important;
  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }
}

// 홈 공지사항 제목 innerHtml
.ntc-title {
  a {
    color: #333;
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }
}

// 공지 내용은 드래그 가능
.bbs-detail-body * {
  user-select: text;
}

// 주 테마 버튼
[trnty-button] {
  border: 1px solid $primary-color;
  border-radius: $border-radius;
  padding: 0 16px;

  $height: 46px;
  height: $height;
  line-height: $height;

  @include primary-theme;
  font-size: 1.125rem;
  cursor: pointer;

  &:disabled {
    border: 1px solid $border-color-2;
    background-color: $border-color-2;
    color: $border-color-3;
  }
}

// 부 테마 버튼
[trnty-button='secondary'] {
  border: 1px solid $secondary-color;
  // background-color: $secondary-color;
  // color: $content-color-1;
  @include secondary-theme;
}

// 경고 테마 버튼
[trnty-button='warn'] {
  border: 1px solid $border-color-1;
  background-color: #fff;
  color: $warn-color;
}

// 조작 불가 버튼
[trnty-button='disabled'] {
  border: 1px solid $border-color-2;
  background-color: $border-color-2;
  color: $border-color-3;
}

div.mat-select-arrow {
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid;
  color: #1d233b;
  margin: 0;
}

mat-dialog-container.mat-dialog-container {
  border-radius: $border-radius;
}

.mat-select-value {
  color: $title-color;
}

.mat-select-placeholder {
  color: $content-color-2;
}

.select-packagegoods.mat-select-disabled .mat-select-value {
  color: $content-color-2;
}

button.mat-button-base.mat-flat-button.mat-primary.mat-button-disabled {
  color: $disabled-color;
  background-color: $disabled-background-color;
}

.mat-button-base.mat-button,
.mat-button-base.mat-icon-button,
.mat-button-base.mat-stroked-button,
.mat-button-base.mat-flat-button {
  border-radius: $border-radius;
}

.checkbox-wrapper {
  .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
  .mat-checkbox-checked.mat-accent .mat-checkbox-background {
    background-color: #145a9f;
  }
}

trnty-packagegoods-list-by-country[header-top-small] {
  @media (max-width: $max-width-s) {
    .country-title br {
      display: none;
    }
  }
}

// 페이지
[trnty-page] {
  min-height: calc(100vh - #{$header-height-mobile});

  @media screen and (min-width: $min-width-m) {
    min-height: calc(
      100vh - #{$header-height-desktop + $header-menu-height-desktop}
    );
  }
}

// 페이지 본문
[trnty-container] {
  margin: 0 auto;
  max-width: $content-max-width;
}

// 패키지 상세의 매장 상세 다이얼로그 패널
.mrhst-description-panel {
  @include bs.media-breakpoint-up(sm) {
    max-width: 1264px;
    max-height: 900px;
  }

  width: 100vw;
  height: 100%;

  .modal-content {
    padding: 0;
    // 스크롤시 헤더에 가려지지 않도록
    scroll-padding-top: 56px;
    // border-radius: 0;

    @media screen and (min-width: $min-width-m) {
      scroll-padding-top: 88px;
    }
  }
}

// 데스크톱 헤더 메뉴
.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none;
}

[trnty-video-wrapper] {
  iframe {
    vertical-align: bottom;
    width: 100%;
    height: auto;
    // 유튜브 기본 종횡비
    aspect-ratio: 16 / 9;
  }
}

// TODO: pw-img 제거 또는 개선
// TODO: swiperJs > bootstrap
// TODO: 이미지 비율 정의
// 패키지 상세 스와이퍼 이미지 비율 16:9 고정, 모바일보다 큰 화면은 4:3
trnty-packagegoods-detail {
  trnty-image-carousel {
    pw-img {
      img {
        aspect-ratio: 16 / 9;

        @media screen and (min-width: $min-width-l) {
          aspect-ratio: 4 / 3;
        }
      }
    }
  }
}

trnty-packagegoods-case-selector {
  border-radius: 0;
}

.required::after {
  content: '*';
  color: var(--bs-danger);
}

.flex-basic-0 {
  flex-basis: 0;
}
